<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.id"
        allow-clear
        label="交易编号"
        v-bind="layout"
        placeholder="请输入交易编号"
        @pressEnter="onSearch"
      />
      <BjInput
        v-model="search.nickname"
        allow-clear
        label="发起用户"
        v-bind="layout"
        placeholder="请输入发起用户的昵称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.type"
        v-bind="layout"
        key-field="id"
        label="交易类型"
        inner-search
        placeholder="全部类型"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
      <BjSelect
        v-model="search.source"
        v-bind="layout"
        key-field="id"
        label="交易场景"
        inner-search
        placeholder="全部场景"
        value-field="id"
        label-field="name"
        show-all
        :options="sourceData"
      />
      <BjValidationItem label="交易时间" v-bind="layout">
        <a-range-picker @change="onChange" />
      </BjValidationItem>
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">交易列表</p>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #user="item">
          <div :class="$style.user">
            <img :src="item.avatar" />
            <span>{{ item.nickname }}</span>
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.status === 0 ? 'default' : item.status === 1 ? 'primary' : 'danger'">
            {{ item.status | state }}
          </BjTag>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
    <bj-modal
      title="详情"
      :visible="visible.detail"
      :body-style="{ height: '400px' }"
      @ok="visible.detail = false"
      @cancel="visible.detail = false"
    >
      <div v-if="detail.id">
        <div :class="$style.box">
          <div :class="$style.left">交易编号</div>
          <div :class="$style.right">
            {{ detail.nickname }}
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">发起用户</div>
          <div :class="$style.right">
            <img :src="detail.avatar" />
            <span class="ml-10 primary">{{ detail.id }}</span>
          </div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易类型</div>
          <div :class="$style.right">{{ detail.type ? '回收' : '发放' }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易场景</div>
          <div :class="$style.right">{{ detail.source_name }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易明细</div>
          <div :class="$style.right">{{ detail.detail }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">积分数量</div>
          <div :class="$style.right">{{ detail.amount }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易状态</div>
          <div :class="$style.right">{{ detail.status | state }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">交易时间</div>
          <div :class="$style.right">{{ detail.created_at }}</div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { integralManageService } from '@/service'

const service = new integralManageService()

export default {
  name: 'Home',
  filters: {
    state(val) {
      switch (val) {
        case 0:
          return '冻结中'
        case 1:
          return '成功'
        case 2:
          return '失败'
      }
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        detail: false,
      },
      search: {
        id: null,
        user_id: null,
        nickname: null,
        type: '',
        source: '',
        start_time: null,
        end_time: null,
      },
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      data: [],
      detail: {},
      sourceData: [
        {
          name: '全部场景',
          id: '',
        },
        {
          name: '任务发放',
          id: 'task',
        },
        {
          name: '消费任务',
          id: 'consume',
        },
        {
          name: '自定义任务',
          id: 'custom',
        },
      ],
      typeData: [
        {
          name: '全部类型',
          id: '',
        },
        {
          name: '全部类型',
          id: 1,
        },

        {
          name: '全部类型',
          id: 2,
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '交易编号',
          dataIndex: 'id',
        },
        {
          title: '发起用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        {
          title: '交易类型',
          dataIndex: 'type',
          customRender: item => (item.group_name ? '回收' : '发放'),
        },
        {
          title: '交易场景',
          dataIndex: 'source_name',
        },
        {
          title: '积分数量',
          dataIndex: 'amount',
        },
        {
          title: '交易状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '交易时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onDetail(item)}>
              详情
            </bj-link>,
          ],
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getDetail({
          ...this.search,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {}
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    onDetail(item) {
      this.visible.detail = true
      this.detail = item
    },
    onChange(date, dateString) {
      this.search.start_time = dateString[0]
      this.search.end_time = dateString[1]
    },
    init() {
      this.search = {
        name: null,
        status: 0,
        group_id: '',
        poc_type_id: [''],
        type: '',
        author_id: '',
      }
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;

    .title {
      height: 36px;
      color: #000;
      font-size: 16px;
      line-height: 36px;
    }

    .user {
      display: flex;
      align-items: center;
      color: @primary-color;

      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
  }
}

.box {
  display: flex;
  align-items: center;
  height: 32px;

  .left {
    width: 110px;
  }

  .right {
    flex: 1;

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}
</style>
